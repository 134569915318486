import React from "react";
import { useAuthContext } from "src/contexts/auth";
import { FiLogOut } from "react-icons/fi";
import {
  Portal,
  MenuList,
  MenuItem as Item,
  MenuDivider,
  Text,
  Icon,
  Badge,
  Flex,
  Link
} from "@chakra-ui/react";
import { AiOutlineMonitor, AiOutlineSetting } from "react-icons/ai";
import { IoWarningOutline } from "react-icons/io5";
import { useRouter } from "next/router";
import { useUserStatusContext } from "src/contexts/users/userStatus";
import { getUserInfo } from "src/utils/tokenHelper";
import { Loader, LOADER_TYPE_MAP } from "src/components/MandalaUI/Loader";
import { USER_TIER_LABEL } from "src/constants/userTiers";

const MenuItem = ({
  Icon: IconComponent,
  onClick,
  title,
  href = "",
  ...props
}) => {
  return (
    <Item
      p=".75em"
      fontSize="xl"
      bgColor="white"
      cursor="pointer"
      border="none"
      borderBottom="solid 1px"
      borderColor="mandala.black.300"
      onClick={onClick}
      href={href}
      as={Link}
      variant="transparent"
      _hover={{
        bgColor: "mandala.blue.200"
      }}
      {...props}
    >
      <Icon as={IconComponent} mr=".75em" />
      <Text color={props.color} fontSize="sm">
        {title}
      </Text>
    </Item>
  );
};

export const NavigationBarMenu = () => {
  const { logout: handleClickLogout } = useAuthContext();
  const { push } = useRouter();
  const {
    userStatusData: { tier: userTier, isFreeUser },
    isUserInformationStatusLoading
  } = useUserStatusContext();
  const { username: currentUsername } = getUserInfo();

  const URLSetting = `/settings/${
    !isFreeUser ? "storage-usage" : "change-password"
  }`;

  // get user plan/tier directly without touching localStorage
  // as we only use once per load
  const getUserPlan = () => {
    return USER_TIER_LABEL[userTier?.toUpperCase()] || userTier;
  };

  // getter to get current user plan
  const currentUserPlan = getUserPlan();

  return (
    <Portal>
      <MenuList
        data-testid="user-setting-dropdown-item"
        zIndex={5}
        bgColor="white"
        border="solid 1px"
        borderColor="mandala.black.300"
        boxShadow="md"
        p="0"
        mt=".1em"
      >
        <Flex
          borderRadius=".3em .3em 0 0"
          direction="column"
          px="1em"
          py=".5em"
          gap="0"
          bgColor="mandala.blue-ddl.100"
        >
          <Text fontSize="xs">Username:</Text>
          <Text
            m="0"
            p="0"
            fontSize="1em"
            fontWeight="bold"
            data-testid="navbar-current-username"
          >
            {currentUsername}
          </Text>
          <Text fontSize="xs" pt=".5em">
            Rolename:
          </Text>
          <Loader
            type={LOADER_TYPE_MAP.SKELETON}
            rounded="full"
            isLoaded={!isUserInformationStatusLoading && userTier}
          >
            <Badge
              rounded="full"
              py={0.5}
              px={2}
              minW="15px"
              fontWeight="700"
              fontSize="sm"
              textTransform="capitalize"
              backgroundColor={isFreeUser ? "mandala.black.200" : "tertiary"}
              color={isFreeUser ? "mandala.black.700" : "#093C43"}
              isTruncated
            >
              {currentUserPlan}
            </Badge>
          </Loader>
        </Flex>

        <MenuDivider m="0" />
        <MenuItem
          data-testid="user-setting-dropdown-item-settings"
          href={URLSetting}
          Icon={AiOutlineSetting}
          title="Settings"
          onClick={e => {
            e.preventDefault();
            push(URLSetting);
          }}
        />
        <MenuItem
          data-testid="user-setting-dropdown-item-monitoring"
          href="/monitoring"
          Icon={AiOutlineMonitor}
          title="Monitoring"
          onClick={e => {
            e.preventDefault();
            push("/monitoring");
          }}
        />
        <MenuItem
          data-testid="user-setting-dropdown-item-report-problem"
          href="mailto:support@delman.io?subject=%5BMandala%20Support%5D%20Please%20enter%20your%20subject"
          Icon={IoWarningOutline}
          title="Report Problems"
        />
        <MenuItem
          borderBottomRadius=".25em"
          href="/"
          data-testid="user-setting-dropdown-item-logout"
          onClick={e => {
            e.preventDefault();
            handleClickLogout();
          }}
          Icon={FiLogOut}
          title="Logout"
          color="mandala.red.500"
        />
      </MenuList>
    </Portal>
  );
};
