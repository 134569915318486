import React from "react";
import {
  Box,
  Menu,
  MenuButton,
  Text,
  Badge,
  Flex,
  Icon
} from "@chakra-ui/react";
import { USER_TIER_LABEL } from "src/constants/userTiers";
import { getUserInfo } from "src/utils/tokenHelper";
import { NavigationBarMenu } from "./NavigationBarMenu";
import { Loader, LOADER_TYPE_MAP } from "src/components/MandalaUI/Loader";
import { useUserStatusContext } from "src/contexts/users/userStatus";
import { RiArrowDownSLine } from "react-icons/ri";

export const NavigationBarUserInfo = () => {
  const {
    userStatusData: { tier: userTier, isFreeUser },
    isUserInformationStatusLoading
  } = useUserStatusContext();

  // get user plan/tier directly without touching localStorage
  // as we only use once per load
  const getUserPlan = () => {
    return USER_TIER_LABEL[userTier?.toUpperCase()] || userTier;
  };

  // getter to get current user plan
  const currentUserPlan = getUserPlan();
  const { username: currentUsername } = getUserInfo();

  return (
    <Menu w="100%">
      <Box>
        <Text pl="1em" fontSize=".75em" color="white">
          Welcome!
        </Text>
        <MenuButton
          data-testid="user-setting-dropdown"
          color="white"
          bgColor="transparent"
          border="none"
          p="0"
          cursor="pointer"
        >
          <Flex gap="0.5em" w="15em" pl=".75em" pr=".5em">
            <Text
              color="white"
              m="0"
              p="0"
              fontSize="1em"
              data-testid="navbar-current-username"
              isTruncated
              maxW="9em"
            >
              {currentUsername}
            </Text>
            <Loader
              type={LOADER_TYPE_MAP.SKELETON}
              rounded="full"
              isLoaded={!isUserInformationStatusLoading && userTier}
            >
              <Badge
                rounded="full"
                py={0.5}
                px={2}
                minW="15px"
                maxW="5em"
                fontWeight="700"
                textTransform="capitalize"
                backgroundColor={isFreeUser ? "mandala.black.200" : "tertiary"}
                color={isFreeUser ? "mandala.black.700" : "#093C43"}
                isTruncated
              >
                {currentUserPlan}
              </Badge>
            </Loader>
            <Icon as={RiArrowDownSLine} color="white" />
          </Flex>
        </MenuButton>
      </Box>
      <NavigationBarMenu />
    </Menu>
  );
};
